<template>
  <quill-editor
    :ref="editorRef"
    v-model="value"
    :options="editorOptions"
  />
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'

const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

export default {
  name: 'QuillEditor',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    editorRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editorOptions: {
        theme: 'snow',
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ['link', 'image', 'video'],
              [{ size: SizeStyle.whitelist }],
              ['bold', 'italic', 'underline', 'strike'],
              ['clean'],
              ['undo', 'redo'],
              ['paragraph'],
            ],
            handlers: {
              image: () => this.imageHandler(),
              link: () => this.linkHandler(),
            },
          },
        },
      },
    }
  },
  methods: {
    imageHandler() {
      const editor = this.$refs[this.editorRef].quill
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()
      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)
        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
           xhr.open('POST', `${ process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
 xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }
            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }
            const json = JSON.parse(xhr.responseText)
            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }
            const range = editor.getSelection()
            editor.insertEmbed(range.index, 'image', json.location)
          }
          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }
          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    },
    linkHandler() {
      const range = this.$refs[this.editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self'
          const linkValue = `<a href="${url}" target="${target}">${this.$refs[this.editorRef].quill.getText(range)}</a>`
          this.$refs[this.editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    },
  },
}
</script>

<style scoped>

.quill-editor {
  max-height: 400px; /* Limit height to prevent overflow */
  overflow-y: auto; /* Add scrollbar if content overflows */
}

.ql-toolbar.ql-snow {
  z-index: 10; /* Ensure toolbar is above other elements */
}

.ql-container.ql-snow {
  z-index: 9; /* Ensure container is below the toolbar */
}
</style>
